import React from "react";
import { Box, Flex, Image, Stack } from "@chakra-ui/react";
import { Title } from "../typography/Title";
import { BodyText } from "../typography/BodyText";
import { useTranslation } from "react-i18next";
import { ArticleAreaSection } from "../layouts/ArticleContainer";

interface ArticleSectionViewProps extends ArticleAreaSection {
  textColor: string;
  textBackground: string;
  specialStyle?: boolean;
}

export const ArticleSectionView: React.FC<ArticleSectionViewProps> = ({
  text,
  image,
  title,
  imageAfter,
  textAfter,
  textBackground = "#F3EFD6",
  textColor = "black",
  specialStyle = false
}) => {
  const { t } = useTranslation();

  const imgComponent = image && (
    <Image src={image} backgroundSize="cover" alt={title || "Image"} />
  );

  const titleComponent = title && (
    <Title
      fontWeight="700"
      lineHeight="1"
      fontFamily="Josefin Sans"
      color={textColor}
      fontSize={{ base: "28px", md: "30px", lg: "32px" }}
      textTransform="uppercase"
    >
      {t(title)}
    </Title>
  );

  const textComponent = text && (
    <BodyText
      fontFamily="DM Sans"
      fontWeight="400"
      lineHeight="24px"
      dangerouslySetInnerHTML={{ __html: t(text) }}
      color={textColor}
    />
  );

  const imgAfterComponent = imageAfter && (
    <Image 
      alignSelf="center"
      objectFit="fill"
      width={{base: "100%",md: "80%" ,lg: "70%"}}
      src={imageAfter} 
      alt={title || "Image"} 
    />
  );

  const textAfterComponent = textAfter && (
    <BodyText
      style={{ fontStyle: 'italic' }}
      fontFamily="DM Sans"
      fontWeight="400"
      lineHeight="24px"
      dangerouslySetInnerHTML={{ __html: t(textAfter) }}
      color={textColor}
    />
  );

  const cardComponent = (titleComponent || textComponent) && (
    <Stack
      alignSelf="center"
      width="100%"
      color={textColor}
      bg={textBackground}
      gap="1.5rem"
      p={{ base: "2rem", lg: "3rem" }}
    >
      {titleComponent}
      {textComponent}
    </Stack>
  );

  const cardAfterImgComponent = textAfterComponent && (
    <Box
      display="flex"
      color={textColor}
      alignSelf="center"
      bg={textBackground}
      p={{ base: "2rem", lg: "3rem" }}
      width={{base: "100%",md: "80%" ,lg: "70%"}}
      marginTop={specialStyle ? "1rem" : "0rem"}
    >
      {textAfterComponent}
    </Box>
  );

  return (
      <Stack width={{base: "100%", lg: "60%"}} paddingY="6.25rem" gap="0" >
        {imgComponent}
        {cardComponent}
        {imgAfterComponent}
        {cardAfterImgComponent}
      </Stack>
  );
};
