import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Flex, Image, Text, Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import SnModal from "../components/modal/SnModal";
import { useVisitedLinks } from "../context/VisitedLinkContext";

interface ImageObject {
  imageUri: string;
  title: string;
  link: string;
  url: string;  
  caption: string;
  modalContent?: (onClose: () => void) => React.ReactNode;
}

interface CollageForOutroProps {
  backgroundImageUri: string;
  images: ImageObject[];
  linkToThisPage: string;
  linkToPrevPage?: string; 
  linkToNextPage?: string; 
}

const CollageForOutro: React.FC<CollageForOutroProps> = ({
  images,
  linkToThisPage,
}) => {
  const { isVisited } = useVisitedLinks();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onClose = () => {
    navigate(linkToThisPage, { replace: true });
  };

  return (
    <Box
      alignSelf="center"
      flexDirection={{ base: "row", lg: "column" }}
      justifyContent="center"
      paddingBottom="3rem"
    >
      <Flex
        alignSelf="center"
        gap="1rem"
        flexDirection={{ base: "column", lg: "row" }}
        justifyContent="center"
        alignContent="center"
      >
        {images.map((image, index) => {
          const isVisitedFilter = isVisited(image.url)
            ? "grayscale(100%) brightness(0.66)"
            : "none";

          return (
            <Flex
              key={index}
              flexDirection="column"
              gap="1rem"
              alignItems="center"
            >
              <Box
                height="auto"
                display="flex"
                alignItems="center"
                justifyContent="center"
                marginTop="3rem"
              >
                <Image
                  src={image.imageUri}
                  alt={image.caption}
                  filter={isVisitedFilter}
                  width="70%"
                  height="auto"
                  objectFit="fill"
                />
              </Box>
              <Flex flexDirection="column" flex="1" alignItems="center">
                <Text
                  fontSize="16px"
                  fontWeight="700"
                  fontFamily="Josefin Sans"
                  paddingBottom="4px"
                  maxW="400px"
                  textAlign="center"
                  color="khaki"
                >
                  {image.title}
                </Text>
                <Text
                  fontSize="1rem"
                  fontWeight="400"
                  maxW="300px"
                  textAlign="center"
                  color="khaki"
                >
                  {image.caption}
                </Text>
              </Flex>
              <Button
                minH="40px"
                minW="150px"
                color="#aed38a"
                background="transparent"
                border="2px solid #aed38a"
                onClick={() => {
                  if (image.url.startsWith("http")) {
                    window.open(image.url, "_blank");
                  } else {
                    navigate(image.url);
                  }
                }}
                _hover={{ background: "rgba(255, 255, 255, 0.1)" }}
                borderRadius="12px"
              >
                {image.link}
              </Button>
              <SnModal isOpen={false} onClose={onClose}>
                {image.modalContent ? image.modalContent(onClose) : null}
              </SnModal>
            </Flex>
          );
        })}
      </Flex>
    </Box>
  );
};

export default CollageForOutro;
