import { Box, Text } from "@chakra-ui/layout";
import { Button, Container, Icon } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FiArrowLeft, FiChevronDown } from "react-icons/fi";
import { NavButtonContainer } from "../layouts/NavButtonContainer";
import { Link } from "react-router-dom";

interface ArticleHeaderProps {
  subtitle?: string;
  title?: string;
  paragraphs: string[];
  onClickScrollDown: () => void;
  titleComponent?: React.ReactNode;
  subtitleComponent?: React.ReactNode;
  backgroundImage?: string;
  alternateBackground?: string;
}

export const ArticleHeader: React.FC<ArticleHeaderProps> = ({
  title,
  subtitle,
  paragraphs,
  onClickScrollDown,
  titleComponent,
  backgroundImage,
  alternateBackground,
}) => {
  const { t } = useTranslation();

  const bg = `url(${backgroundImage})`;

  const alterBg = `url(${alternateBackground})`;
  return (
    <Box
      as="header"
      minHeight="100vh"
      backgroundImage={{ base: alterBg ,md: alterBg, lg: bg }}
      backgroundSize={{ base: "cover", md: "cover", lg: "cover" }}
      backgroundPosition="center"
      position="relative"
      paddingBottom="3rem"
    >
      <Container
        height="100%"
        paddingLeft={{ base: "10%", lg: "26%" }}
        paddingTop="20vh"
        maxWidth={{ base: "100%", md: "100%", lg: "60%", xl: "50%" }}
        paddingBottom="3rem"
      >
        <Link to="/2">
          <Button
            minH="40px"
            minW="150px"
            color="#aed38a"
            background="transparent"
            border="2px solid #aed38a"
            _hover={{ background: "rgba(255, 255, 255, 0.1)" }}
            borderRadius="12px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            padding="8px 16px"
          >
            <Icon as={FiArrowLeft} fontSize="22px" marginRight="8px" />
            {t("intro.back")}
          </Button>
        </Link>
        {title && (
          <Text
            color="khaki"
            fontSize={{ base: "30px", lg: "2.5rem" }}
            lineHeight="44px"
            marginTop="1.5rem"
            fontWeight="700"
            fontFamily="Josefin Sans"
            style={{ textTransform: "uppercase" }}
          >
            {t(title)}
          </Text>
        )}
        {titleComponent}
        {subtitle && (
          <Text
            fontSize="1rem"
            lineHeight="24px"
            fontWeight="700"
            color="khaki"
            marginTop="1.5rem"
            fontFamily="Josefin Sans"
            style={{ textTransform: "uppercase" }}
          >
            {t(subtitle)}
          </Text>
        )}
        {paragraphs.map((x) => (
          <Text
            key={x}
            fontSize="16px"
            color="khaki"
            fontWeight="400"
            marginTop="1.5rem"
            lineHeight="24px"
            fontFamily="DM Sans"
            dangerouslySetInnerHTML={{ __html: t(x) }}
          />
        ))}
      </Container>
      <NavButtonContainer pos="absolute">
        <Button
          variant="unstyled"
          color="lightGreen"
          _hover={{
            color: "#639599",
          }}
          display="flex"
          onClick={onClickScrollDown}
          flexDir="column"
        >
          {t("article.scroll")}
          <Icon fontSize="1.5rem">
            <FiChevronDown />
          </Icon>
        </Button>
      </NavButtonContainer>
    </Box>
  );
};
