import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Flex, Image, Text, Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import SnModal from "../components/modal/SnModal";
import { useVisitedLinks } from "../context/VisitedLinkContext";
import NavigationContainer from "../components/layouts/NavigationContainer";
import { Title } from "../components/typography/Title";
import { ArticleMaps } from "./ArticleMaps";

interface ImageObject {
  imageUri: string;
  caption: string;
  title: string;
  bodyText: string;
  modalContent: (onClose: () => void) => React.ReactNode;
}

interface CollagePageProps {
  backgroundImageUri: string;
  images: ImageObject[];
  linkToThisPage: string;
  linkToNextPage: string;
  linkToPrevPage: string;
}

const CollagePage: React.FC<CollagePageProps> = ({
  backgroundImageUri,
  images,
  linkToNextPage,
  linkToPrevPage,
  linkToThisPage,
}) => {
  const { modal } = useParams<{ modal: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const { isVisited } = useVisitedLinks();
  const { t } = useTranslation();
  const Names = ["Alice", "Pallada", "Akula"];

  const onClose = () => {
    navigate(linkToThisPage, { replace: true });
  };

  return (
    <Flex
      alignSelf="center"
      flexDirection={{ base: "row", lg: "column" }}
      gap="13rem"
      justifyContent="center"
      paddingBottom="3rem"
    >
      <Flex
        alignSelf="center"
        gap="7rem"
        flexDirection={{ base: "column" ,lg: "row" }}
        justifyContent="center"
        alignContent="center"
      >
        {images.map((image, index) => {
          const pageName = Names[index] || `image-${index}`;
          const url = `${location.pathname}/${pageName}`;
          const isOpen = modal === pageName;
          const isVisitedFilter = isVisited(url)
            ? "grayscale(100%) brightness(0.66)"
            : "transparent";
          return (
            <Flex key={index} flexDirection="column" gap="1rem">
              <Image
                minHeight={{ md: "280px" }}
                minWidth={{ md: "230px" }}
                src={image.imageUri}
                alt={image.caption}
                filter={isVisitedFilter}
                objectFit="cover"
              />
              <Flex flexDirection="column" flex="1">
              <Title
                  fontWeight="700"
                  fontSize="1rem"
                  textAlign="center"
                  color="khaki"
                  lineHeight="1.25rem"
                  dangerouslySetInnerHTML={{ __html: image.title }}
                ></Title>
                <Text
                  flex="1"
                  textAlign="center"
                  color="white"
                  wordBreak="keep-all"
                >
                  {image.bodyText}
                </Text>
              </Flex>
              <Button
                minH="40px"
                minW="150px"
                color="#aed38a"
                background="transparent"
                border="2px solid #aed38a"
                onClick={() => navigate(url)}
                _hover={{ background: "rgba(255, 255, 255, 0.1)" }}
                borderRadius="12px"
              >
                {t("intro.read")}
              </Button>
              <SnModal isOpen={isOpen} onClose={onClose}>
                {image.modalContent(onClose)}
              </SnModal>
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
};

export default CollagePage;