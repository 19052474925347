import React, { useRef, useState } from "react";
import { Box, Container } from "@chakra-ui/react";
import { ArticleHeader } from "../typography/ArticleHeader";
import { ArticleSectionView } from "../articles/ArticleSectionView";
import StickyArea from "./sticky/StickyArea";
import { ArticleFooter } from "./ArticleFooter";

interface ArticleContainerProps {
  onClose: () => void;
  title: string;
  footerTitle?: string;
  headerParagraphs: string[];
  headerBackground: string;
  alternateBackground: string;
  subtitle: string;
  areas: ArticleArea[];
  footerParagraphs: string[];
}

export interface ArticleArea {
  useScrollAnimation: boolean;
  items: Array<ArticleAreaSection>;
  background?: string;
}

export interface ArticleConfig {
  articleId: string;
  title: string;
  subtitle: string;
  headerParagraphs: string[];
  footerParagraphs: string[];
  areas: ArticleArea[];
  headerBackground: string;
  alternateBackground: string;
}

export interface ArticleAreaSection {
  background?: string;
  title?: string;
  text?: string;
  image?: string;
  imageAfter?: string;
  textAfter?: string;
  isSpecialText?: boolean;
  textColor?: string;
  textBackground?: string;
  specialStyle?: boolean;
}

const ArticleContainer: React.FC<ArticleContainerProps> = ({
  onClose,
  title,
  headerParagraphs,
  headerBackground,
  alternateBackground,
  subtitle,
  areas,
  footerParagraphs,
  footerTitle,
}) => {
  const scrollContainer = useRef<HTMLDivElement | null>(null);
  const markerDiv = useRef<HTMLDivElement | null>(null);

  const handleClickScrollDown = () => {
    markerDiv.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  };


  return (
    <Box height="100vh" overflowY="auto" position="relative" background="white" ref={scrollContainer}>
      <ArticleHeader alternateBackground={alternateBackground} backgroundImage={headerBackground} onClickScrollDown={handleClickScrollDown} title={title} paragraphs={headerParagraphs} subtitle={subtitle}/>
      <Box ref={markerDiv}></Box>
      {areas.map((area, areaIndex) => (
        <StickyArea key={areaIndex} sections={area.items} scrollContainerRef={scrollContainer}>
          <Container display="flex" flexDirection="column" alignItems="center" maxWidth="100%" position="relative">
            {area.items.map((item, itemIndex) => {
              const textBackground = item.textBackground || "#F3EFD6"; 
              const color = item.textColor || "black"; 

              return (
                <ArticleSectionView
                  key={itemIndex}
                  {...item}
                  textBackground={textBackground}
                  textColor={color}
                />
              );
            })}
          </Container>
        </StickyArea>
      ))}
      <ArticleFooter title={footerTitle} paragraphs={footerParagraphs} onClickBack={onClose} socialTitle={title} />
    </Box>
  );
};

export default ArticleContainer;
