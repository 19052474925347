import React, { useEffect } from "react";
import { Route, useLocation } from "react-router-dom";
import SlideRoutes from "../components/animations/SlideRoutes";
import { Intro } from "../pages/Intro";
import { Main } from "../pages/Main";
import { Outro } from "../pages/Outro";
import { QuotePage } from "../pages/QuotePage";
import { Credits } from "../pages/Credits";
import { CookieConsent } from "../ga4/ga4";

interface AppRoutsProps {}

const AppRouts: React.FC<AppRoutsProps> = () => {
  return (
    <>
      <CookieConsent />
      <SlideRoutes duration={300}>
        <Route index element={<Intro />} path="" />
        <Route element={<QuotePage />} path="/1" />
        <Route element={<Main />} path="/2/:modal?" />
        <Route element={<Outro />} path="/3" />
        <Route element={<Credits />} path="credits" />
      </SlideRoutes>
    </>
  );
};

export default AppRouts;
