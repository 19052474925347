import { ButtonProps, Icon } from "@chakra-ui/react";
import { FiArrowLeft } from "react-icons/fi";
import { NavButton } from "../buttons/NavButton";

interface Props extends ButtonProps {

}

export const NavLeft = (props: Props) => {

  return (
    <NavButton {...props}><Icon as={FiArrowLeft} fontSize="22px" pos="absolute" /></NavButton>
  )
}