import { SnLink } from "./typography/SnLink";
import { Text, TextProps } from "@chakra-ui/react";

export const CreditCopyright = (props: TextProps) => {
  return (
    <Text fontWeight="700" fontFamily="700" fontSize="16px" lineHeight="24px" color="khaki" {...props}>
      Copyright © 2023-2024. All rights reserved.<br />
      Created by{" "}
      <SnLink to="https://atcontent.fi/" target="_blank">John Nurminen Foundation</SnLink>{" "}and{" "}
      <SnLink to="https://www.indevit.fi/" target="_blank">Indevit</SnLink>
    </Text>
  );
};
