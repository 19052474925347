import { Modal, ModalContent, ModalCloseButton, Box, Link } from "@chakra-ui/react";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { BodyText } from "../typography/BodyText";
import { Title } from "../typography/Title";
import { useTranslation } from "react-i18next";
import { Copyright } from "../Copyright";

interface InfoModalProp {
  isOpen: boolean;
  onClose: () => void;
}

export default function InfoModal({ isOpen, onClose }: InfoModalProp) {
  const { t } = useTranslation();
  
  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalContent
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
        padding="3rem"
        background="#164136"
        gap="1rem"
      >
        <ModalCloseButton color="#F3EFD6" />
        <Title fontSize="18px" lineHeight="24px" color="#F3EFD6"
        dangerouslySetInnerHTML={{ __html: t("info.title") }}>
        </Title>
        <BodyText fontSize="16px" lineHeight="24px" color="#F3EFD6">
          {t("info.body.0")}
        </BodyText>
        <BodyText fontSize="16px" lineHeight="24px" color="#F3EFD6">
          {t("info.body.1")}
        </BodyText>
        <Box alignSelf="flex-start" display="flex" flexDirection="row" gap="1rem">
          <Title fontSize="18px" lineHeight="24px" color="#F3EFD6">
            <FiArrowLeft />
          </Title>
          <Title fontSize="18px" lineHeight="24px" color="#F3EFD6">
            <FiArrowRight />
          </Title>
        </Box>
        <BodyText fontSize="16px" lineHeight="24px" color="#F3EFD6">
          {t("info.body.2")}
        </BodyText>
        <Copyright/>
      </ModalContent>
    </Modal>
  );
}
