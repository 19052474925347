import { ChakraProps, Flex, Stack, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { Whatsapp } from "./WhatsApp";
import { Facebook } from "./Facebook";
import { Twitter } from "./Twitter";
import { colors } from "../../themes/Theme";

export interface ShareWithYourFriendsProps extends ChakraProps {
  title: string;
  urlToShare?: string;
  color?: string;
}

export const ShareWithYourFriends: React.FC<ShareWithYourFriendsProps> = ({ color, title, urlToShare, ...chakra }) => {

  const { t } = useTranslation();

  urlToShare = urlToShare ?? window.location.toString();

  title = t(title);

  return (
    <Stack marginBottom="1px" gap="1.5rem" display="flex" flexDir="column" alignItems="center" {...chakra}>
      <Text color={color || "brand"} fontWeight="700">{t("shareWithYourFriends")}</Text>
      <Flex gap="1.5rem">
        <Facebook title={title} color={color ?? colors.brand} urlToShare={urlToShare} />
        <Twitter title={title} color={color ?? colors.brand} urlToShare={urlToShare} />
        <Whatsapp title={title} color={color ?? colors.brand} urlToShare={urlToShare} />
      </Flex>
    </Stack>
  )
}