import { Text, TextProps } from "@chakra-ui/react";
import React from "react";

export const BodyText: React.FC<TextProps> = (props) => {
    return <Text
    color="brand"
    fontSize="1rem"
    fontWeight="400"
    lineHeight="24px"
    fontFamily="DM Sans"
    {...props}>
    </Text>
}