import React from "react";
import { Box, Container, VStack, Image, Icon } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { BodyText } from "../components/typography/BodyText";
import { Title } from "../components/typography/Title";
import { NavButtonContainer } from "../components/layouts/NavButtonContainer";
import { Link } from "react-router-dom";
import { NavButton } from "../components/buttons/NavButton";
import usePreLoadImage from "../hooks/PreloadImage";
import { FiArrowRight } from "react-icons/fi";
import { Copyright } from "../components/Copyright";

export const Intro: React.FC = () => {
  const { t } = useTranslation();

  usePreLoadImage([
    "/images/background/background-03.jpg",
  ]);

  

  return (
    <Box
      height="calc(100vh - 40px)"
      backgroundImage="/images/background/background-01.jpg"
      position="relative"
      backgroundSize="cover"
      display="flex"
      justifyContent="center"
      backgroundPosition="center"
      alignItems="center"
    >
      <Container
        pt="2%"
        id="intro-container"
        display="flex"
        height="100%"
        maxW="72ch"
        pb="6rem"
        flexDir="column"
        justifyContent="space-between"
      >
        <VStack
          alignItems="flex-start"
          gap="24px"
          overflowY="auto"  
        >
          <Image
            alignSelf="center"
            src={t("intro.image")}
            height="100px"
          />
          <Title fontSize={{base: "40px", lg: "56px"}} pt="1rem" color="khaki">{t("intro.title")}</Title>

          <BodyText fontFamily="Josefin Sans" fontSize="18px" color="khaki" textAlign="left" lineHeight={{base: "1.25rem", lg: "1.5rem"}}>
            {t("intro.welcome")}
          </BodyText>

          <Box fontFamily="DM Sans" fontSize="16px" color="khaki" textAlign="left" lineHeight={{base: "1.25rem", lg: "1.5rem"}}
          dangerouslySetInnerHTML={{ __html: t("intro.diveInto") }}>
          </Box>

          <BodyText fontFamily="DM Sans" fontSize="16px" color="khaki" textAlign="left" lineHeight={{base: "1.25rem", lg: "1.5rem"}}>
            {t("intro.subbody")}
          </BodyText>

          <Copyright />
        </VStack>
      </Container>
      <NavButtonContainer>
        <Link to="1">
          <NavButton pos="relative" green>
            {t("intro.start")}
            <Icon marginLeft="12px" fontSize="1.5rem">
              <FiArrowRight />
            </Icon>
          </NavButton>
        </Link>
      </NavButtonContainer>
    </Box>
  );
};
