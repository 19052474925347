import { Button, ButtonProps } from "@chakra-ui/react";

export interface PrimaryButtonProps extends ButtonProps {
  scheme?: "brand" | "white" | "lightGreen"
}

export const PrimaryButton: React.FC<PrimaryButtonProps> = (props) => {
  const { scheme, ...chakra } = props;
  const bg = scheme == "white" ? "white" : scheme == "lightGreen"? "lightGreen" : "brand";
  const color = scheme == "white" ? "brand" : scheme == "lightGreen" ? "brand" :"white";

  return (<Button
    background={bg}
    minHeight="48px"
    _hover={{
      background: bg
    }}
    _focus={{
      background: bg
    }}
    color={color}
    {...chakra} />)
}