import { Box, Text } from "@chakra-ui/layout";
import { Button, Container, Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { ShareWithYourFriends } from "../social/ShareWithYourFriend";
import { Title } from "../typography/Title";
import { PrimaryButton } from "../buttons/PrimaryButton";

interface ArticleFooterProps {
  title?: string;
  socialTitle: string;
  paragraphs: string[];
  onClickBack: () => void;
}

export const ArticleFooter: React.FC<ArticleFooterProps> = ({
  paragraphs,
  onClickBack,
  title,
  socialTitle,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      as="footer"
      height="100vh"
      backgroundImage="url('/images/background/background-02.jpg')"
      backgroundSize="cover"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
    >
      <Container
        display="flex"
        height="100%"
        gap="40px"
        flexDir="column"
        justifyContent="space-between"
        paddingBottom="24px"
      >
        <Flex
          direction="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          gap="24px"
          flexGrow={1}
        >
          <Box maxW="240px" textAlign="center">
            <Box
              backgroundImage="url('/images/arch/Arch-07.png')"
              backgroundSize="contain"
              backgroundPosition="center"
              backgroundRepeat="no-repeat"
              height={{ base: "150px", md: "240px" }}
              margin="3rem auto 0"
            />
            <Text
              size="14px"
              pt="10px"
              color="white"
              fontSize="lg"
              dangerouslySetInnerHTML={{ __html: t("footers.caption") }}
            />
          </Box>
          <a
            href="https://kauppa.johnnurmisensaatio.fi/products/sodan-sumua-ensimmainen-maailmansota-suomenlahdella"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              maxH="40px"
              minW="150px"
              color="#aed38a"
              background="transparent"
              border="2px solid #aed38a"
              _hover={{ background: "rgba(255, 255, 255, 0.1)" }}
              borderRadius="12px"
            >
              {t("shop")}
            </Button>
          </a>
        </Flex>
        <Flex direction="column">
          {title && <Title color="white">{t(title)}</Title>}
          {paragraphs.map((x) => (
            <Text key={x} color="white" dangerouslySetInnerHTML={{ __html: t(x) }} />
          ))}
        </Flex>
        <PrimaryButton scheme="lightGreen" onClick={onClickBack}>
          {t("footers.back")}
        </PrimaryButton>
        <Box>
          <ShareWithYourFriends color="white" title={socialTitle} />
        </Box>
      </Container>
    </Box>
  );
};
