import React from "react";
import { Box, Container } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Title } from "../components/typography/Title";
import { BodyText } from "../components/typography/BodyText";
import { NavButtonContainer } from "../components/layouts/NavButtonContainer";
import { NavLeft } from "../components/layouts/NavLeft";
import { NavButton } from "../components/buttons/NavButton";
import CollageForOutro from "./CollageForOutro";
import { useTranslation } from "react-i18next";

export const Outro: React.FC = () => {
  const { t } = useTranslation();

  const images = [
    {
      imageUri: "/images/arch/Arch-04.png",
      url: "https://kauppa.johnnurmisensaatio.fi/products/sodan-sumua-ensimmainen-maailmansota-suomenlahdella",
      title: t("outro.title1"),
      link: t("outro.link1"),
      caption: t("outro.outcaption1"),
    },
    {
      imageUri: "/images/arch/Arch-05.png",
      link: t("outro.link2"),
      url: "https://johnnurmisensaatio.fi/",
      title: t("outro.title2"),
      caption: t("outro.outcaption2"),
    },
    {
      imageUri: "/images/arch/Arch-06.png",
      link: t("outro.link3"),
      url: "https://johnnurmisensaatio.fi/auta-itamerta/lahjoita/",
      title: t("outro.title3"),
      caption: t("outro.outcaption3"),
    },
  ];

  return (
    <Box
      backgroundImage="/images/background/background-04.jpg"
      position="relative"
      height="calc(100vh - 40px)"
      backgroundSize="cover"
      backgroundPosition="center"
      overflowX="hidden"
    >
      <Box
        height="100%"
        overflowY="auto"
        paddingTop={{ base: "60px", md: "px" }}
      >
        <Container
          display="flex"
          paddingTop="2%"
          maxW="72ch"
          flexDir="column"
          gap="24px"
        >
          <Title
            textAlign="center"
            alignSelf="center"
            color="khaki"
            fontSize={{ base: "2rem", lg: "2.5rem" }}
            fontWeight="700"
            lineHeight="2.75rem"
          >
            {t("outro.title")}
          </Title>
          <BodyText color="white">
            {t("outro.subtitle")}
          </BodyText>
        </Container>
        <Box
          mx="auto"
          width="60%"
          sx={{
            paddingBottom: {
              base: "25%",
              md: "5%",
              lg: "5%",
              xl: "0",
            },
          }}
        >
          <CollageForOutro
            backgroundImageUri="/images/main/1.jpg"
            linkToPrevPage="/"
            linkToThisPage="/1"
            linkToNextPage="/2"
            images={images}
          />
        </Box>
        <Box alignItems="center" position="fixed" bottom="0" width="100%">
          <NavButtonContainer>
            <Link to="/2">
              <NavLeft />
            </Link>
            <Link to="/">
              <NavButton pos="relative">{t("intro.end")}</NavButton>
            </Link>
          </NavButtonContainer>
        </Box>
      </Box>
    </Box>
  );
};

 