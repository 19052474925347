import { Button, ButtonProps } from "@chakra-ui/react";

interface Props extends ButtonProps {
  green?: boolean;
  primary?: boolean;
}

export const NavButton = (props: Props) => {
  const { green, primary, ...chakra } = props;
  const color = green ? "black" : primary ? "#191D43" : "white";
  const background = green? "#aed38a" : primary ? "white" : "rgba(255, 255, 255, 40%)"
  const borderColor = green? "#aed38a" : primary ? "white" : "tranparent"

  return (
    <Button
      border={borderColor}
      background={background}
      color={color}
      padding="24px 16px"
      minW="50px"
      fontFamily="DM Sans"
      borderRadius="32px"
      {...chakra}>
    </Button>
  )
}