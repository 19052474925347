import React from "react";
import ArticleContainer, { ArticleConfig } from "../components/layouts/ArticleContainer";

export interface ArticleMapsProps {
  onClose: () => void;
  articles: ArticleConfig;
}

export const ArticleMaps: React.FC<ArticleMapsProps> = ({ onClose, articles }) => {
  return <ArticleContainer onClose={onClose} {...articles} />;
};
