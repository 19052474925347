import { ButtonProps, Icon } from "@chakra-ui/react";
import { FiArrowRight } from "react-icons/fi";
import { NavButton } from "../buttons/NavButton";

interface Props extends ButtonProps {

}

export const NavRight = (props: Props) => {

  return (
    <NavButton primary {...props}><Icon as={FiArrowRight} fontSize="22px" pos="absolute" /></NavButton>
  )
}