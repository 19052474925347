import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import { BodyText } from "../components/typography/BodyText";
import { useTranslation } from "react-i18next";

const initGA = (measurementId: string, gtagOptions: {}) => {
  ReactGA.initialize(measurementId, { gtagOptions: gtagOptions });
};

const logPageView = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname + window.location.search,
  });
};

export const CookieConsent = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const consent = localStorage.getItem("cookieConsent");
  const windowLocation = window.location.pathname + window.location.search;
  const { t } = useTranslation();

  useEffect(() => {
    if (consent === "true")
      initGA("G-S48586FSWQ", {
        allow_analytics_storage: true,
        allow_ads_storage: true,
      });
    else
      initGA("G-S48586FSWQ", {
        allow_analytics_storage: false,
        allow_ads_storage: false,
      });
  }, [consent]);

  useEffect(() => {
    if (consent === undefined || consent === null) onOpen();
  }, []);

  useEffect(() => {
    if (consent === "true") logPageView();
    else if (consent === undefined || consent === null) onOpen();
  }, [windowLocation]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent marginLeft="1rem" marginRight="1rem" borderRadius="0px">
        <ModalCloseButton />
        <ModalHeader></ModalHeader>
        <ModalBody display="flex" alignSelf="center" padding="1rem">
          <BodyText fontSize="0.9rem">
            {t("cookieConsentText")}{" "}
            <a
              style={{ color: "darkGreen", textDecoration: "underline" }}
              href="https://johnnurmisensaatio.fi/tietosuojaseloste/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("cookieConsentReadMore")}
            </a>
          </BodyText>
        </ModalBody>

        <ModalFooter display="flex" gap="1rem">
          <Button
            onClick={() => {
              localStorage.setItem("cookieConsent", "false");
              onClose();
            }}
            borderRadius="0"
            background="white"
            border="1px solid darkGreen"
          >
            {t("cookieConsentDecline")}
          </Button>
          <Button
            onClick={() => {
              localStorage.setItem("cookieConsent", "true");
              onClose();
            }}
            borderRadius="0"
            background="white"
            backgroundColor="darkGreen"
            color="white"
          >
            {t("cookieConsentAccept")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
