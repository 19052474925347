import React from "react";
import { Box, Container, HStack, VStack, Image, Icon } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { BodyText } from "../components/typography/BodyText";
import { Title } from "../components/typography/Title";
import { NavButtonContainer } from "../components/layouts/NavButtonContainer";
import { Link } from "react-router-dom";
import { NavButton } from "../components/buttons/NavButton";
import usePreLoadImage from "../hooks/PreloadImage";
import { FiArrowRight } from "react-icons/fi";
import { NavLeft } from "../components/layouts/NavLeft";
import { NavRight } from "../components/layouts/NavRight";


export const QuotePage: React.FC = () => {
  const { t } = useTranslation();

  usePreLoadImage([
    "/images/arch/Arch-01.png",
    "/images/arch/Arch-02.png",
    "/images/arch/Arch-03.png",
    "/images/background/background-03.jpg"
  ]);


  return (
    <Box
      height="calc(100vh - 40px)"
      backgroundImage="/images/background/background-03.jpg"
      position="relative"
      backgroundSize="cover"
      display="flex"
      justifyContent="center"
      backgroundPosition="center"
      alignItems="center"
    >
      <Container
        id="intro-container"
        display="flex"
        height="100%"
        overflowY="auto"
        pt="10%"
        maxW="700px"
        pb="6rem"
        flexDir="column"
        justifyContent="space-between"
      >
        <VStack alignItems="center" gap="24px">
          <BodyText fontSize={{ base: "20px", md: "25px", lg: "32px" }} fontWeight="600" fontFamily="Josefin Sans" color="white" textAlign="center" lineHeight={{ base: "1.75rem", lg: "2.375rem" }}>{t("intro.quote")}</BodyText>
          <BodyText fontSize={{ base: "15px", md: "18px", lg: "20px" }} fontWeight="400" fontFamily="DM Sans" color="white" textAlign="center" lineHeight={{ base: "1.25rem", lg: "1.5rem" }}>{t("intro.author")}</BodyText>
        </VStack>
      </Container>
      <NavButtonContainer>
        <Link to="/">
          <NavLeft />
        </Link>
        <Link to="/2">
          <NavRight />
        </Link>
      </NavButtonContainer>
    </Box>
  );
};
