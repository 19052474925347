import React from "react";
import CollagePage from "./CollagePage";
import { ArticleMaps } from "./ArticleMaps";
import { useTranslation } from "react-i18next";
import { background, Box, Container } from "@chakra-ui/react";
import { BodyText } from "../components/typography/BodyText";
import { Title } from "../components/typography/Title";
import { NavButtonContainer } from "../components/layouts/NavButtonContainer";
import { Link } from "react-router-dom";
import { NavLeft } from "../components/layouts/NavLeft";
import { NavRight } from "../components/layouts/NavRight";
import usePreLoadImage from "../hooks/PreloadImage";


export const Main: React.FC = () => {
  const { t } = useTranslation();


  usePreLoadImage([
    "/images/arch/Arch-04.png",
    "/images/arch/Arch-05.png",
    "/images/arch/Arch-06.png",
    "/images/background/background-04.jpg",
    "/images/02alice/background-07.jpg",
    "/images/03pallada/background-08.jpg",
    "/images/04alkula/background-09.jpg"
  ]);

  const articleConfig1 = {
    articleId: "article1",
    title: t("article.alice.header.title"),
    subtitle: t("article.alice.header.subtitle"),
    headerParagraphs: [t("article.alice.header.body")],
    alternateBackground: t("article.alice.header.alternateBackground"),
    headerBackground: t("article.alice.header.background"),
    footerParagraphs: [t("footers.body"), t("footers.author")],
    areas: [
      {
        useScrollAnimation: true,
        items: [
          {
            title: t("article.alice.titles.0"),
            text: t("article.alice.bodies.0"),
            textColor: "darkGreen",
          },
          {
            background: "/images/02alice/Background-alice-01.jpg",
            imageAfter: "/images/02alice/alice-01.png",
            textAfter: "article.alice.captions.0",
            textColor: "darkGreen",
            textBackground: "white"
          },
          {
            text: t("article.alice.bodies.1"),
          },
          {
            title: t("article.alice.titles.1"),
            text: t("article.alice.bodies.2"),
            textColor: "darkGreen"
          },
          {
            background: "/images/02alice/Background-alice-02.jpg",
            imageAfter: "/images/02alice/alice-02.jpg",            
            textAfter: "article.alice.captions.1",
            textColor: "darkGreen",
            specialStyle: true
          },
          {
            text: t("article.alice.bodies.3"),
            textColor: "darkGreen"
          },
          {
            background: "/images/02alice/Background-alice-02.jpg",
            imageAfter: "/images/02alice/alice-03.png",
            textAfter: "article.alice.captions.2",
            textBackground: "white",
            textColor: "darkGreen"
          },
          {
            text: t("article.alice.bodies.4"),
            textColor: "darkGreen"

          },
          {
            text: t("article.alice.bodies.5"),
            textColor: "darkGreen"

          },
          {
            text: t("article.alice.bodies.6"),
            textColor: "darkGreen"

          },
          {
            text: t("article.alice.bodies.7"),
            textColor: "darkGreen"

          },
          {
            imageAfter: "/images/02alice/alice-04.png",
            textAfter: "article.alice.captions.3",
            textColor: "darkGreen",
            textBackground: "white"
          },
          {
            text: t("article.alice.bodies.8"),
            textColor: "darkGreen"

          },
          {
            text: t("article.alice.bodies.9"),
            background: "/images/02alice/Background-alice-03.jpg",
            title: t("article.alice.titles.2"),
            textBackground: "darkGreen",
            textColor: "white",
          },
          {
            textBackground: "darkGreen",
            text: t("article.alice.bodies.10"),
            textColor: "white"
          },
          {
            imageAfter: "/images/02alice/alice-05.png",           
             textAfter: "article.alice.captions.4",
            textBackground: "darkGreen",
            textColor: "white",
            specialStyle: true
          }, 
          {
            imageAfter: "/images/02alice/alice-06.png",
            textAfter: "article.alice.captions.5",
            textBackground: "darkGreen",
            textColor: "white",
            specialStyle: true
          }, 
          {
            imageAfter: "/images/02alice/alice-07.png",
            textAfter: "article.alice.captions.6",
            textBackground: "darkGreen",
            textColor: "white",
            specialStyle: true
          },

          {
            textBackground: "darkGreen",
            text: t("article.alice.bodies.11"),
            textColor: "white"
          },
          {
            title: t("article.alice.titles.3"),
            text: t("article.alice.bodies.12"),
            textColor: "white",
            textBackground: "darkGreen"
          },
          {
            background: "/images/02alice/Background-alice-04.jpg",
            imageAfter: "/images/02alice/alice-08.png",
            textAfter: "article.alice.captions.7",
            textBackground: "darkGreen",
            textColor: "white",
            specialStyle: true

          },
          {
            imageAfter: "/images/02alice/alice-09.png",
            textAfter: "article.alice.captions.8",
            textBackground: "darkGreen",
            textColor: "white",
            specialStyle: true,
          },
          {
            imageAfter: "/images/02alice/alice-10.png",
            textAfter: "article.alice.captions.9",
            textBackground: "darkGreen",
            textColor: "white",
            specialStyle: true
          },
          {
            text: t("article.alice.bodies.13"),
            textBackground: "darkGreen",
            textColor: "white"
          },

        ],
      },
    ],
  };



  const articleConfig2 = {
    articleId: "article2",
    title: t("article.pallada.header.title"),
    subtitle: t("article.pallada.header.subtitle"),
    headerParagraphs: [t("article.pallada.header.body")],
    headerBackground: t("article.pallada.header.background"),
    alternateBackground: t("article.pallada.header.alternateBackground"),
    footerParagraphs: [t("footers.body"), t("footers.author")],
    areas: [
      {
        useScrollAnimation: true,
        items: [
          {
            background: "/images/03pallada/Background-pallada-01.jpg",
            title: t("article.pallada.titles.0"),
            text: t("article.pallada.bodies.0"),
            textColor: "darkGreen"
          },
          {
            text: t("article.pallada.bodies.1"),
            textColor: "darkGreen"

          },
          {
            text: t("article.pallada.bodies.2"),
            textColor: "darkGreen"

          },
          {
            textColor: "darkGreen",
            text: t("article.pallada.bodies.3")
          },
          {
            imageAfter: "/images/03pallada/pallada-01.png",
            textAfter: "article.pallada.captions.0",
            textColor: "darkGreen",
            textBackground: "white"

          },
          {
            text: t("article.pallada.bodies.4"),
            textColor: "darkGreen"

          },
          {
            text: t("article.pallada.bodies.5"),
            textColor: "darkGreen"

          },
          {
            background: "/images/03pallada/Background-pallada-02.jpg",
            imageAfter: "/images/03pallada/pallada-02.png",
            textAfter: "article.pallada.captions.1",
            textColor: "darkGreen",
            textBackground: "white"


          },
          {
            text: t("article.pallada.bodies.6"),
            textColor: "darkGreen"

          },
          {
            text: t("article.pallada.bodies.7"),
            textColor: "darkGreen"

          },
          {
            text: t("article.pallada.bodies.8"),
            textColor: "darkGreen"

          },
          {
            imageAfter: "/images/03pallada/pallada-03.png",
            textAfter: "article.pallada.captions.2",
            textColor: "darkGreen",
            textBackground: "white"

          },
          {
            text: t("article.pallada.bodies.9"),
            textColor: "darkGreen"

          },
          {
            text: t("article.pallada.bodies.10"),
            textColor: "darkGreen"

          },
          {
            imageAfter: "/images/03pallada/pallada-04.png",
            textAfter: "article.pallada.captions.3",
            textColor: "darkGreen",
            textBackground: "white"
          },
          {
            text: t("article.pallada.bodies.11"),
            textColor: "darkGreen"

          },
          {
            background: "/images/03pallada/Background-pallada-03.jpg",
            title: t("article.pallada.titles.1"),
            text: t("article.pallada.bodies.12"),
            textColor: "white",
            textBackground: "darkGreen"
          },
          {
            text: t("article.pallada.bodies.13"),
            textColor: "white",
            textBackground: "darkGreen"
          },
          {
            text: t("article.pallada.bodies.14"),
            textColor: "white",
            textBackground: "darkGreen"
          },
          {
            text: t("article.pallada.bodies.15"),
            textColor: "white",
            textBackground: "darkGreen"
          },
          {
            imageAfter: "/images/03pallada/pallada-05.png",
            textAfter: "article.pallada.captions.4",
            textColor: "darkGreen",
            textBackground: "white"
          },
          {
            text: t("article.pallada.bodies.16"),
            textColor: "white",
            textBackground: "darkGreen"
          },
          {
            imageAfter: "/images/03pallada/pallada-06.png",
            textAfter: "article.pallada.captions.5",
            textColor: "darkGreen",
            textBackground: "white"
          },
          {
            text: t("article.pallada.bodies.17"),
            textColor: "white",
            textBackground: "darkGreen"
          },
          {
            imageAfter: "/images/03pallada/pallada-07.png",
            textAfter: "article.pallada.captions.6",
            textColor: "darkGreen",
            textBackground: "white"
          },
          {
            imageAfter: "/images/03pallada/pallada-08.png",
            textAfter: "article.pallada.captions.7",
            textColor: "darkGreen",
            textBackground: "white"
          },
          {
            text: t("article.pallada.bodies.18"),
            textColor: "white",
            textBackground: "darkGreen"
          },
          {
            background: "/images/03pallada/Background-pallada-04.jpg",
            imageAfter: "/images/03pallada/pallada-09.png",
            textAfter: "article.pallada.captions.8",
            textColor: "darkGreen",
            textBackground: "white"
          },
          {
            imageAfter: "/images/03pallada/pallada-10.png",
            textAfter: "article.pallada.captions.9",
            textColor: "darkGreen",
            textBackground: "white"
          },
          {
            imageAfter: "/images/03pallada/pallada-11.png",
            textAfter: "article.pallada.captions.10",
            textColor: "darkGreen",
            textBackground: "white"
          },
          {
            text: t("article.pallada.bodies.19"),
            textColor: "white",
            textBackground: "darkGreen"
          },
          {
            text: t("article.pallada.bodies.20"),
            textColor: "white",
            textBackground: "darkGreen"
          },
          {
            imageAfter: "/images/03pallada/pallada-12.png",
            textAfter: "article.pallada.captions.11",
            textColor: "darkGreen",
            textBackground: "white"
          },
          {
            background: "/images/03pallada/Background-pallada-05.jpg",
            text: t("article.pallada.bodies.21"),
            textColor: "white",
            textBackground: "darkGreen"
          },
          {
            text: t("article.pallada.bodies.22"),
            textColor: "white",
            textBackground: "darkGreen"
          },
          {
            text: t("article.pallada.bodies.23"),
            textColor: "white",
            textBackground: "darkGreen"
          },
          {
            text: t("article.pallada.bodies.24"),
            textColor: "white",
            textBackground: "darkGreen"
          },
          {
            imageAfter: "/images/03pallada/pallada-13.png",
            textAfter: "article.pallada.captions.12",
            textColor: "darkGreen",
            textBackground: "white"
          },
          {
            imageAfter: "/images/03pallada/pallada-14.png",
            textAfter: "article.pallada.captions.13",
            textColor: "darkGreen",
            textBackground: "white"
          },
          {
            text: t("article.pallada.bodies.25"),
            textColor: "white",
            textBackground: "darkGreen"
          },
          {
            text: t("article.pallada.bodies.26"),
            textColor: "white",
            textBackground: "darkGreen"
          },
        ]
      }
    ]
  };

  const articleConfig3 = {
    articleId: "article3",
    title: t("article.alkula.header.title"),
    subtitle: t("article.alkula.header.subtitle"),
    headerParagraphs: [t("article.alkula.header.body")],
    headerBackground: t("article.alkula.header.background"),
    alternateBackground: t("article.alkula.header.alternateBackground"),
    footerParagraphs: [t("footers.body"), t("footers.author")],
    areas: [
      {
        useScrollAnimation: true,
        items: [
          {
            background: "/images/04alkula/Background-alkula-01.jpg",
            title: t("article.alkula.titles.0"),
            text: t("article.alkula.bodies.0"),
            textColor: "darkGreen"
          },
          {
            text: t("article.alkula.bodies.1"),
            textColor: "darkGreen"

          },
          {
            imageAfter: "/images/04alkula/akula-01.png",
            textAfter: "article.alkula.captions.0",
            textColor: "darkGreen",
            textBackground: "white"
          },
          {
            text: t("article.alkula.bodies.2"),
            textColor: "darkGreen"

          },
          {
            text: t("article.alkula.bodies.3"),
            textColor: "darkGreen"

          },
          {
            background: "/images/04alkula/Background-alkula-02.jpg",
            title: t("article.alkula.titles.1"),
            text: t("article.alkula.bodies.4"),
            textColor: "white",
            textBackground: "darkGreen"
          },
          {
            imageAfter: "/images/04alkula/akula-02.png",
            textAfter: "article.alkula.captions.1",
            textColor: "darkGreen",
            textBackground: "white"
          },
          {
            text: t("article.alkula.bodies.5"),
            textColor: "white",
            textBackground: "darkGreen"
          },
          {
            imageAfter: "/images/04alkula/akula-03.png",
            textAfter: "article.alkula.captions.2",
            textColor: "darkGreen",
            textBackground: "white"
          },
          {
            background: "/images/04alkula/Background-alkula-03.jpg",
            text: t("article.alkula.bodies.6"),
            textColor: "white",
            textBackground: "darkGreen"
          },
          {
            text: t("article.alkula.bodies.7"),
            textColor: "white",
            textBackground: "darkGreen"
          },
          {
            text: t("article.alkula.bodies.8"),
            textColor: "white",
            textBackground: "darkGreen"
          },
          {
            imageAfter: "/images/04alkula/akula-04.png",
            textAfter: "article.alkula.captions.3",
            textBackground: "white"
          },
          {
            text: t("article.alkula.bodies.9"),
            textColor: "white",
            textBackground: "darkGreen"
          },
          {
            imageAfter: "/images/04alkula/akula-05.jpg",
            textAfter: "article.alkula.captions.4",
            textBackground: "white"
          },
          {
            background: "/images/04alkula/Background-alkula-04.jpg",
            text: t("article.alkula.bodies.10"),
            textColor: "white",
            textBackground: "darkGreen"
          },
          {
            imageAfter: "/images/04alkula/akula-06.png",
            textAfter: "article.alkula.captions.5",
            textBackground: "white"
          },
          {
            text: t("article.alkula.bodies.11"),
            textColor: "white",
            textBackground: "darkGreen"
          },
          {
            text: t("article.alkula.bodies.12"),
            textColor: "white",
            textBackground: "darkGreen"
          },
          {
            text: t("article.alkula.bodies.13"),
            textColor: "white",
            textBackground: "darkGreen"
          },

        ]
      }
    ]
  };

  const images = [
    {
      imageUri: "/images/arch/Arch-01.png",
      caption: t(""),
      title: t("articleMaps.sn1.title"),
      bodyText: t(""),
      modalContent: (onClose: () => void) => (
        <ArticleMaps
          onClose={onClose}
          articles={articleConfig1}
        />
      ),
    },
    {
      imageUri: "/images/arch/Arch-02.png",
      caption: t(""),
      title: t("articleMaps.sn2.title"),
      bodyText: t(""),
      modalContent: (onClose: () => void) => (
        <ArticleMaps
          onClose={onClose}
          articles={articleConfig2}
        />
      ),
    },
    {
      imageUri: "/images/arch/Arch-03.png",
      caption: t(""),
      title: t("articleMaps.sn3.title"),
      bodyText: t(""),
      modalContent: (onClose: () => void) => (
        <ArticleMaps
          onClose={onClose}
          articles={articleConfig3}
        />
      ),
    },
  ];

  return (
    <Box
      backgroundImage="/images/background/background-03.jpg"
      position="relative"
      height="calc(100vh - 40px)"
      backgroundSize="cover"
      backgroundPosition="center"
      overflowX="hidden"
    >
      <Box height="100%" overflowY="auto" paddingTop={{ base: "60px", md: "px" }}>
        <Container
          display="flex"
          pt="30px"
          maxW="72ch"
          flexDir="column"
          gap="20px"
        >
          <Title textTransform="uppercase" fontWeight="700" fontFamily="Josefin Sans" color="khaki" fontSize={{ base: "1.7rem", lg: "2rem" }} lineHeight={{base: "1.5", lg: "2.25rem"}}>
            {t("main.title")}
          </Title>
          <BodyText color="khaki" lineHeight="1.5rem" fontSize= "1rem" >
            {t("main.body")}
          </BodyText>
          <BodyText color="khaki" lineHeight="1.5rem" fontSize="1rem">
            {t("main.subbody")}
          </BodyText>
        </Container>
        <Box mx="auto"
          width="45%"
          sx={{
            paddingBottom: { base: '120px', md: '2%' },
            paddingTop: { base: '100px', md: '2%' },
          }}>
          <CollagePage
            backgroundImageUri=""
            linkToPrevPage="/1"
            linkToThisPage="/2"
            linkToNextPage="/3"
            images={images}
          />
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" position="fixed" bottom="0" width="100%">
        <NavButtonContainer>
          <Link to="/1">
            <NavLeft />
          </Link>
          <Link to="/3">
            <NavRight />
          </Link>
        </NavButtonContainer>
      </Box>
    </Box>
  );
};
