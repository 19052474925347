import React from "react";
import { Box, Container, Stack, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Title } from "../components/typography/Title";
import { BodyText } from "../components/typography/BodyText";
import { CreditCopyright } from "../components/CreditCopyright";

export const Credits: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box
      minHeight="calc(100vh - 40px)"
      backgroundImage="/images/background/background-03.jpg"
      backgroundSize="cover"
      backgroundPosition=" center "
      paddingTop="112px"
      paddingBottom="3rem"
      position="relative"
    >
      <Container
        display="flex"
        height="100%"
        maxW="72ch"
        pb="1rem"
        flexDir="column"
        justifyContent="space-between"
      >
        <VStack alignItems="flex-start" gap="24px">
          <Box w="85vh">
            <Box>
              <Title
                fontFamily="PT Sans"
                fontWeight="700"
                fontSize={{ base: "40px", lg: "48px" }}
                color="khaki"
              >
                {t("credits.title")}
              </Title>
            </Box>
            <Box maxW={{ base: "300px", lg: "600px" }} marginTop="32px">
              <Stack spacing="8px">
                <BodyText
                  fontFamily="DM Sans"
                  fontWeight="400"
                  fontSize="16px"
                  lineHeight="24px"
                  color="khaki"
                  dangerouslySetInnerHTML={{ __html: t("credits.subtitle") }}
                />
                <BodyText
                  fontFamily="DM Sans"
                  fontWeight="400"
                  fontSize="16px"
                  lineHeight="24px"
                  color="khaki"
                  dangerouslySetInnerHTML={{ __html: t("credits.body") }}
                />
                <BodyText
                  fontFamily="DM Sans"
                  fontWeight="400"
                  fontSize="16px"
                  lineHeight="24px"
                  color="khaki"
                >
                  {t("credits.organization.leni")}
                </BodyText>
                <BodyText
                  fontFamily="DM Sans"
                  fontWeight="400"
                  fontSize="16px"
                  lineHeight="24px"
                  color="khaki"
                >
                  {t("credits.organization.indevit")}
                </BodyText>
              </Stack>
            </Box>
            <Box
              maxW={{ base: "350px", md: "450px", lg: "500px" }}
              marginTop="32px"
            >
              <CreditCopyright />
            </Box>
          </Box>
        </VStack>
      </Container>
    </Box>
  );
};
