import { Flex, Stack, Link as ChakraLink, Box } from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { Title } from "../typography/Title";
import { colors } from "../../themes/Theme";

interface HamburgerMenuProps {
  headerRef: React.RefObject<HTMLHeadingElement>;
  isOpen: boolean;
  closeHamburgerMenu: () => void;
}

const HamburgerMenu: React.FC<HamburgerMenuProps> = ({
  headerRef,
  isOpen,
  closeHamburgerMenu,
}) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    closeHamburgerMenu();
  }, [location.pathname]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        closeHamburgerMenu();
      }
    };

    if (isOpen) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen, closeHamburgerMenu]);

  const links = [
    {
      route: "/",
      name: "mainCaptions.start",
    },
    {
      route: "/2",
      name: "mainCaptions.article",
    },
    {
      indent: true,
      route: "/2/Alice",
      name: "mainCaptions.leftImage",
    },
    {
      indent: true,
      route: "/2/Pallada",
      name: "mainCaptions.middleImage",
    },
    {
      indent: true,
      route: "/2/Akula",
      name: "mainCaptions.rightImage",
    },
    {
      route: "/3",
      name: "mainCaptions.outro",
    },
  ];

  return (
    <>
      {isOpen && (
        <Box
          position="fixed"
          width="100vh"
          height="100vh"
          zIndex="19999"
          bg="transparent"
          onClick={closeHamburgerMenu}
        />
      )}
      <Flex
        position="fixed"
        transform={isOpen ? "translateX(0)" : "translateX(100%)"}
        transition="transform 0.2s"
        top={headerRef.current?.clientHeight + "px"}
        right={0}
        zIndex={20000}
        padding="1rem"
        w={{ base: "100%", lg: "40%" }}
        h="100%"
        marginLeft="400px"
        paddingTop="100px"
        justifyContent="center"
        ref={menuRef}
        bg="rgba(22, 65, 54, 0.5)"
        backdropFilter="blur(10px)"
      >
        <Stack spacing="2rem">
          {/* <Title color="white">{t("intro.subtitle")}</Title> */}

          {links.map((x) => {
            const ifIndentText = x.indent ? "3rem" : "0";
            const ifIndentBefore = x.indent
              ? {
                  content: x.indent ? "'•'" : "''",
                  marginRight: "1rem",
                }
              : {};
            return (
              <ChakraLink
                as={Link}
                key={x.route}
                to={x.route}
                color="white"
                fontSize={{ base: "1rem", lg: "1.5rem" }}
                fontFamily="DM Sans"
                fontWeight="700"
                textIndent={ifIndentText}
                _before={ifIndentBefore}
              >
                {t(x.name)}
              </ChakraLink>
            );
          })}
          <ChakraLink
            as={Link}
            to="/credits"
            color="white"
            fontSize={{ base: "1rem", lg: "1.5rem" }}
            fontFamily="DM Sans"
            fontWeight="700"
          >
            {t("mainCaptions.credits")}
          </ChakraLink>
        </Stack>
      </Flex>
    </>
  );
};

export default HamburgerMenu;
 