import { Box, ChakraProvider, useDisclosure } from "@chakra-ui/react";
import Heading from "./components/layouts/Heading";
import { useEffect, useRef } from "react";
import { useHamburgerMenu } from "./hooks/HamburgerMenu";
import HamburgerMenu from "./components/menus/HamburgerMenu";
import { BrowserRouter, useLocation } from "react-router-dom";
import AppRouts from "./routes/routes";
import { colors, defaultTheme } from "./themes/Theme";
import { useTranslation } from "react-i18next";
import InfoModal from "./components/modal/InfoModal";

function App() {
  const { isOpen, toggleOpen, closeMenu } = useHamburgerMenu();
  const headerRef = useRef<HTMLHeadingElement>(null);

  const { t } = useTranslation();

  const { isOpen: infoOpen, onOpen, onClose } = useDisclosure();

  const toggleInfoModal = () => {
    if (infoOpen) {
      onClose();
    } else {
      onOpen();
    }
  };

  return (
    <ChakraProvider theme={defaultTheme}>
      <BrowserRouter>
        <Box background={colors.appBackground} minHeight="100vh">
          <Heading
            ref={headerRef}
            toggleHamburgerMenu={toggleOpen}
            toggleInfoModal={toggleInfoModal}
          />
          <HamburgerMenu
            headerRef={headerRef}
            isOpen={isOpen}
            closeHamburgerMenu={closeMenu}
          />
          <InfoModal onClose={onClose} isOpen={infoOpen} />
          <AppRouts />
        </Box>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
