import {
  Flex,
  IconButton,
  Heading,
  Box,
  HeadingProps,
  forwardRef,
} from "@chakra-ui/react";
import { FiInfo, FiMenu } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

interface SNHeadingProps extends HeadingProps {
  toggleHamburgerMenu: () => void;
  toggleInfoModal: () => void;
}

const SNHeading: React.ForwardRefRenderFunction<
  HTMLHeadingElement,
  SNHeadingProps
> = ({ toggleHamburgerMenu, toggleInfoModal }, ref) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const languages = ["EN", "FI"];

  const getLanguageFromURL = () => {
    const params = new URLSearchParams(location.search);
    return params.get("lang")?.toUpperCase() || null;
  };

  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(
    getLanguageFromURL
  );

  useEffect(() => {
    const currentLang = getLanguageFromURL();
    if (!currentLang) {
      navigate({
        search: `?lang=${selectedLanguage?.toLocaleLowerCase()}`,
      });
    } else if (!languages.includes(currentLang)) {
      navigate({
        search: `?lang=fi`,
      });
    }
  }, [location.search]);

  const handleLanguageChange = (lang: string) => {
    i18n.changeLanguage(lang.toLowerCase());
    setSelectedLanguage(lang);

    navigate({
      search: `?lang=${lang.toLowerCase()}`,
    });
  };

  useEffect(() => {
    const currentLang = getLanguageFromURL();
    if (currentLang && languages.includes(currentLang)) {
      i18n.changeLanguage(currentLang.toLowerCase());
      setSelectedLanguage(currentLang);
    }
  }, [location.search]);

  const languageButtons = languages.map((lang, index) => (
    <Box
      as="button"
      key={lang}
      fontSize="16px"
      px="1rem"
      borderLeft={index > 0 ? "2px solid white" : ""}
      transition="color .1s"
      color={lang === selectedLanguage ? "lightGreen" : "white"}
      _hover={{
        color: "#FAE686",
      }}
      fontWeight="500"
      fontFamily="Inter"
      onClick={() => handleLanguageChange(lang)}
    >
      {t(`language${lang}`)}
    </Box>
  ));

  return (
    <Heading
      display="flex"
      position="sticky"
      top="0"
      zIndex={20000}
      width="100%"
      justifyContent="flex-end"
      backgroundColor="darkGreen"
      ref={ref}
      padding="0px 48px"
    >
      <Flex justifyContent="space-between" alignItems="center" width="100%">
        <Box
          fontFamily="DM Sans"
          color="white"
          fontSize="16px"
          fontWeight="500"
        >
          {" "}
        </Box>
        <Flex justifyContent="flex-end" alignItems="center">
          {languageButtons}
          <IconButton
            ml="2.75rem"
            mr="2rem"
            color="white"
            aria-label="Sound"
            fontSize="1.75rem"
            variant="ghost"
            transition="color .1s"
            _hover={{
              color: "#FAE686",
            }}
            _active={{}}
            icon={<FiInfo />}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation();
              toggleInfoModal();
            }}
          />
          <IconButton
            color="white"
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation();
              toggleHamburgerMenu();
            }}
            transition="color .1s"
            _hover={{
              color: "#FAE686",
            }}
            _active={{}}
            fontSize="1.75rem"
            aria-label="Menu"
            variant="ghost"
            icon={<FiMenu />}
          />
        </Flex>
      </Flex>
    </Heading>
  );
};

export default forwardRef(SNHeading);
